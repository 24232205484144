import React, { Component } from 'react';

class Work extends Component {
    render () {
        return (
            <section className="work-area section-padding" id="work">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center section-title-padding">
                                <h2>My Works</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <ul className="work-filter text-center">
                            <li className="filter" data-filter="all">All</li>
                            <li className="filter" data-filter=".ios">iOS</li>
                            <li className="filter" data-filter=".react-native">React Native</li>
                            <li className="filter" data-filter=".react">React</li>
                        </ul>
                    </div>
                </div>
                <div className="portfolio-items">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="joy mix ios">
                                <div className="single-item">
                                    <a href="/assets/images/cust/smart-halal-shots-2.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/smart-halal-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>Smart Halal (iOS)</h2>
                                            <h3>Smart Halal lets user discover Halal certified restaurants, shops, and services around norther Thailand. It shows highlights on maps ranging up to 10 Kilometers from user current location.</h3>
                                            <h3>Nov 2016 – Jan 2017</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="joy mix ios">
                                <div className="single-item">
                                    <a href="/assets/images/cust/spark-asia-shots.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/spark-asia-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>Spart Asia (iOS) - 2016</h2>
                                            <h3>Spark Asia iOS application in cooperation with Singapore-based team. A free photo printing service that lets you submit digital photos and received printed photos mailed to you.</h3>
                                            <h3>May 2016 – May 2016</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="joy mix ios">
                                <div className="single-item">
                                    <a href="/assets/images/cust/dlt-shots.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/dlt-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>DLT-RSF (iOS) - 2015</h2>
                                            <h3>iOS application for Department of Land Transport. The project called Road Safe Fund provide support victims and encourage safety on the road. Application provide information on License place auction date and time. News and Events.</h3>
                                            <h3>2015</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="joy mix ios">
                                <div className="single-item">
                                    <a href="/assets/images/cust/kmb-shots.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/kmb-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>KMB (iOS) - 2014</h2>
                                            <h3>App that lets you view 3D model of building built in celebration of His Majesty King Bhumibol Adulyadej 84th Birthday. The building is located inside Chiang Mai University. Besides 3D model, you can also view 360 degree pictures of the building both inside and outside.</h3>
                                            <h3>2014</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className=" joy mix react">
                                <div className="single-item">
                                    <a href="/assets/images/cust/smarter-mice-shots.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/smarter-mice-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>Smart MICE (React)</h2>
                                            <h3>Web application for MICE (Meetings, incentives, conferencing, exhibitions) management and attendee involvement. Event design and setup help boost audience engagement. Results analysis from feedback and answers.</h3>
                                            <h3>2017</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className=" joy mix react-native">
                                <div className="single-item">
                                    <a href="/assets/images/cust/cms-shots.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/cms-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>Chiang Mai Spirit (iOS, Android)</h2>
                                            <h3>Browse current and upcoming events and festivals in Chiang Mai. Direction to interesting places. Text-to-speech for foreigners. See suggested routes across Chiang Mai.</h3>
                                            <h3>2017</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className=" joy mix ios">
                                <div className="single-item">
                                    <a href="/assets/images/cust/me-nutra-shots.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/me-nutra-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>Me-nutra (iOS)</h2>
                                            <h3>Mobile commerce application. Shop Me-nutra product anywhere, anytime. Payment gateway from 2C2P. Firebase as backend service.</h3>
                                            <h3>2018</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className=" joy mix ios">
                                <div className="single-item" onClick={() => {}}>
                                    <a href="/assets/images/cust/ebuero-shots.png" className="popup">
                                        <span className="project-img">
                                            <img src="/assets/images/cust/ebuero-icon.png" alt="" />
                                        </span>
                                        <div className="project-info">
                                            <h2>ebuero (iOS)</h2>
                                            <h3>Secretatial service. Colaborate with real customer service agents. View missed calls and messages. Schedule daily plan for virtual secretary.</h3>
                                            <h3>2012 - 2017</h3>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Work