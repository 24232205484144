import React, { Component } from 'react';

class Intro extends Component {
  render() {
    return (
      <div className="mid-header-area" id="home">
        <div className="mid-table">
          <div className="mid-table-cell">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="mid-content">
                    <h1>Thanakrit Weekhamchai</h1>
                    <h4 className="cd-headline clip is-full-width">
                      <span className="cd-words-wrapper">
                        <b className="is-visible"><span role="img" aria-label="Apple">🍎</span> + <span role="img" aria-label="Android">🤖</span> Flutter</b>
                        <b><span role="img" aria-label="Apple">🍎</span> + <span role="img" aria-label="Android">🤖</span> React Native</b>
                        <b><span role="img" aria-label="iOS">📱</span> iOS</b>
                        <b><span role="img" aria-label="Website">🖥</span> React</b>
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Intro;