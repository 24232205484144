import React, { Component } from 'react';

class WhatIDo extends Component {
    render () {
        return (
            <section className="service-area bgcolor1 section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="section-title text-center section-title-padding">
                                <h2>What I Do</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 col-sm-4">
                            <div className="service-wrapper">
                                <div className="service-icon">
                                    <i className="fa fa-mobile" aria-hidden="true"></i>
                                </div>
                                <h3>Flutter Apps</h3>
                                <p>Flutter streamlines cross-platform app development with a single codebase, enabling seamless iOS and Android deployment. Its hot reload feature and versatile widget library ensure efficient UI creation for native-like experiences on all platforms.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="service-wrapper">
                                <div className="service-icon">
                                    <i className="fa fa-mobile" aria-hidden="true"></i>
                                </div>
                                <h3>React Native Apps</h3>
                                <p>Cross-platform application development for both iOS and Android. Fast pace technology for customer who wants app on both platforms for half of the time.</p>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4">
                            <div className="service-wrapper">
                                <div className="service-icon">
                                    <i className="fa fa-desktop" aria-hidden="true" style={{ paddingTop: 3 }}></i>
                                </div>
                                <h3>React Web Apps</h3>
                                <p>Rapid web app development. Get your web online as soon as 30 minutes.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default WhatIDo;