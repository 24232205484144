import React from 'react';

export default function() {
    return (
        <div className="preloader">
            <div className="status">
                <div className="status-mes"></div>
            </div>
        </div>
    );
}