import React, { Component } from 'react';

class HeaderMenu extends Component {
  render() {
    return (
      <header className="site-header">
        <nav id="site-navigation" className="main-navigation navbar">
          <div className="container">
            <div className="row">
              <div className="col-md-2 col-sm-2">
                <div className="navbar-header">
                  <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse">
                    <span className="sr-only">Toggle navigation</span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                    <span className="icon-bar"></span>
                  </button>
                  {/* <a className="logo" href="index.html">
                                            <img src="assets/images/logo.png" alt="site logo" />
                                        </a> */}
                </div>
              </div>
              <div className="col-md-10 col-sm-10">
                <div className="collapse navbar-collapse" id="main-menu">
                  <ul className="nav navbar-nav navbar-right">
                    <li><a href="#home">Home</a></li>
                    <li><a href="#about">About</a></li>
                    <li><a href="#work">Works</a></li>
                    <li><a href="#contact">Contact</a></li>
                    {/* <li><a href="https://medium.com/@dumbest" target="blank">Blog</a></li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </nav>
      </header>
    );
  }
}

export default HeaderMenu;