import React, { Component } from 'react';
// import { repoStars } from '../actions/MainAction';

class ContactStrip extends Component {

  // constructor(props) {
  //     super(props);

  //     this.state = {
  //         stars: 0
  //     };
  // }

  // componentDidMount() {
  //     repoStars(stars => {
  //         this.setState({ stars: stars });
  //     });
  // }

  render() {
    return (
      <section className="get-start section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-sm-6">
              <div className="get-wrapper">
                {/* <h4>Do you want to start your own business?</h4>
                                <p>I am here for you If you think it's going to be easy, think again Entrepreneurship</p> */}
                {/* <p>Total GitHub Stars: {this.state.stars}</p> */}
              </div>
            </div>
            {/* <div className="col-md-4 col-sm-6">
                            <a href="#" className="contact-me">Contact me</a>
                        </div> */}
          </div>
        </div>
      </section>
    );
  }
}

export default ContactStrip;
