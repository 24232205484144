import React, { Component } from 'react';
import Script from 'react-load-script';
// import logo from './logo.svg';
// import './App.css';
import Preloader from './views/Preloader';
import HeaderMenu from './views/HeaderMenu';
import Intro from './views/Intro';
import Contact from './views/Contact';
// import Testimonial from './views/Testimonial';
import Work from './views/Work';
import ContactStrip from './views/ContactStrip';
// import Team from './views/Team';
import WhatIDo from './views/WhatIDo';
import About from './views/About';

class App extends Component {
  handleScriptCreate() {
    // this.setState({ scriptLoaded: false })
  }

  handleScriptError() {
    // this.setState({ scriptError: true })
    // console.log('Load script error');
  }

  handleScriptLoad() {
    // this.setState({ scriptLoaded: true })
  }

  render() {
    return (
      <div>
        <Preloader />
        <HeaderMenu />

        <Intro />

        <About />

        <WhatIDo />

        {/* <Team /> */}


        <Work />

        {/* <Testimonial /> */}

        <ContactStrip />

        <Contact />

        {/* <div id="google-map" data-latitude="18.783651" data-longitude="98.959924"></div> */}

        <div className="scrolltotop">
          <i className="fa fa-chevron-up" aria-hidden="true"></i>
        </div>

        <footer className="footer-area">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="footer-inner-content text-center">
                  <p> &copy; 2018-{new Date().getFullYear()} Thanakrit Weekhamchai | All Rights Reserved.</p>
                  <div className="footer-social-icon">
                    <ul>
                      <li><a href="https://github.com/dumbest" target="blank"><i className="fa fa-github" aria-hidden="true"></i></a></li>
                      {/* <li><a href="https://medium.com/@dumbest" target="blank"><i className="fa fa-medium" aria-hidden="true"></i></a></li> */}
                      <li><a href="https://www.linkedin.com/in/thweekhamchai/" target="blank"><i className="fa fa-linkedin" aria-hidden="true"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Script
          url="/assets/js/custom.js"
          onCreate={this.handleScriptCreate.bind(this)}
          onError={this.handleScriptError.bind(this)}
          onLoad={this.handleScriptLoad.bind(this)}
        />
      </div>
    );
  }
}

export default App;
