import React, { Component } from 'react';
import { useForm, ValidationError } from '@formspree/react';

class Contact extends Component {
  contactForm() {
    const [state, handleSubmit] = useForm("mwkgbvaw");

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="contact-form">
            <form onSubmit={handleSubmit}>
              <div className="single col-md-6">
                <input type="text" name="name" id="full-name" className="controller" placeholder="Your Name" required={true} />
              </div>
              <div className="single col-md-6">
                <input type="email" name="email" className="controller-email" placeholder="Your Email" required={true} />
                <ValidationError
                  className="error-message"
                  prefix="Email"
                  field="email"
                  errors={state.errors}
                />
              </div>
              <div className="single col-md-12">
                <textarea name="message" id="message" cols={25} rows={6} placeholder="Message" required={true}></textarea>
                <ValidationError
                  className="error-message"
                  prefix="Message"
                  field="message"
                  errors={state.errors}
                />
              </div>
              <div className="single col-md-6 form-submit">
                <input type="submit" name="comment-sub" value={state.submitting ? 'Sending' : 'Submit'} disabled={state.submitting} />
                {(state.succeeded && !state.submitting && !state.errors) && <p className="form-submit-thanks">Thanks!</p>}
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <section className="contact-area section-padding" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title section-title-padding text-center">
                <h2>contact me</h2>
              </div>
            </div>
          </div>
          <this.contactForm />
        </div>
      </section>
    );
  }
}

export default Contact;